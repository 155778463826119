import { Button, FormLayout, Pagination, Text } from "@shopify/polaris";
import { template } from "lodash";
import { ICustomerEmailDummy } from "@smartrr/shared/entities/CustomerEmailConfig/mocks";
import { CardHeaderWrapper, EmailPreviewUpper } from "../styles";
import { EmailPreviewWrapper } from "@vendor-app/app/_sharedComponents/NotificationEmails/EmailPreviewModal/lib/constants";

interface EmailPreviewProps {
  description: string;
  title: string;
  onPreview: () => void;
  showEmailPreview: boolean;
  previewHTML: string | undefined;
  context: ICustomerEmailDummy;
  pagination: {
    onNext: () => void;
    onPrevious: () => void;
  };
}

export const EmailPreview = ({
  description,
  title,
  onPreview,
  pagination,
  showEmailPreview,
  context,
  previewHTML,
}: EmailPreviewProps) => {
  return (
    <EmailPreviewUpper>
      <FormLayout.Group helpText={description}>
        <CardHeaderWrapper>
          <Text variant="headingMd" as="h2">
            {title}
          </Text>
          <div className="button-group">
            <Button onClick={onPreview}>Preview</Button>
            <Pagination hasPrevious onPrevious={pagination.onPrevious} hasNext onNext={pagination.onNext} />
          </div>
        </CardHeaderWrapper>
      </FormLayout.Group>

      {!!showEmailPreview && (
        <EmailPreviewWrapper>
          <div
            className="email-preview-inner"
            dangerouslySetInnerHTML={{
              __html: template(previewHTML)(context),
            }}
          />
        </EmailPreviewWrapper>
      )}
    </EmailPreviewUpper>
  );
};
